'use client'

import { FC, useContext, useEffect, useState } from 'react'
import { Loader, OnboardingTemplateProps } from '@papercutsoftware/pcds-react'
import { useSearchParams } from 'next/navigation'
import { getIdentityProviders, IdentityProvider, ProviderType } from '@/api/identityProviders'
import { createIDTokenCookie } from '@/api/idtokenCookie'
import { getTenant } from '@/api/tenants'
import LoginSignUpPageHeading from '@/components/LoginSignUpPageHeading/LoginSignUpPageHeading'
import OtherLoginOptions from '@/components/OtherLoginOptions/OtherLoginOptions'
import { renderError } from '@/components/RenderAlert/RenderErrorAlert'
import SSOLogin from '@/components/SSOLogin/SSOLogin'
import { Product, ProductContext } from '@/context/product'
import { StyledFormWrapper } from '@/styles/firebaseUi.styles'
import { ErrorMessage, toMessage } from '@/utils/errorCodes'
import { isLocalHost } from '@/utils/localhost'
import { errorQueryParam, prefilledEmailAddressQueryParam } from '@/utils/pageurl/pageurl'

interface Props {
  tenantId: string
  setIllustrationName: (name: OnboardingTemplateProps['illustrationName']) => void
}

const TenantLogin: FC<Props> = ({ tenantId, setIllustrationName }) => {
  const [identityProviders, setIdentityProviders] = useState<IdentityProvider[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [tenantName, setTenantName] = useState<string | undefined>(undefined)

  const searchParams = useSearchParams()
  const prefilledEmail = searchParams.get(prefilledEmailAddressQueryParam) ?? ''
  const urlErrorMsg = searchParams.get(errorQueryParam) ? toMessage(searchParams.get(errorQueryParam)!) : null
  const [error, setError] = useState<ErrorMessage | null>(urlErrorMsg)
  const product = useContext(ProductContext)

  const shouldShowSSOLogin = (): boolean => {
    const hasSSO = identityProviders.some((idP) => idP.type === ProviderType.SAML)
    if (hasSSO) {
      const onlyHasSSO = identityProviders.every((idP) => idP.type === ProviderType.SAML)
      const authOption = searchParams.get('authOption')

      // If the organization has only SSO, or if the authOption is 'sso' or missing, show SSO login
      return onlyHasSSO || authOption === 'sso' || authOption == null
    }

    return false
  }

  useEffect(() => {
    setIllustrationName('onboarding')
  }, [setIllustrationName])

  useEffect(() => {
    const loadTenantData = async () => {
      if (!tenantId || !product) return

      setIsLoading(true)

      const fetchIdentityProviders = (async () => {
        try {
          const response = await getIdentityProviders(tenantId)
          if (response) {
            setIdentityProviders(response.identityProviders)
          }
        } catch (error) {
          setError({
            content: `Error fetching identity providers: ${JSON.stringify(error)}`,
          })
        }
      })()

      const fetchTenantName = (async () => {
        try {
          const tenant = await getTenant(tenantId, product.getId())
          setTenantName(tenant?.displayName)
        } catch (error) {} // Catch errors but do not do anything with them as we will default to not showing the tenant name
      })()

      // Wait for both promises to complete (success or error)
      await Promise.all([fetchIdentityProviders, fetchTenantName])

      // Set isLoading to false only after both calls are done
      setIsLoading(false)
    }

    loadTenantData()
  }, [tenantId, product])

  if (isLoading) {
    return <Loader />
  }

  return (
    <StyledFormWrapper direction="column" spacing={2}>
      {isVisible && <LoginSignUpPageHeading tenantName={tenantName} />}
      {error ? renderError(error) : <></>}
      {shouldShowSSOLogin() ? (
        <SSOLogin
          tenantId={tenantId}
          providers={identityProviders}
          onAuthSuccess={(idToken) => onAuthSuccess(idToken, product)}
          onAuthFailure={onAuthFailure}
          onVisibilityChange={(visible) => {
            setIsVisible(visible)
          }}
          setAuthError={setError}
        />
      ) : (
        <OtherLoginOptions
          tenantId={tenantId!}
          providers={identityProviders}
          onAuthSuccess={(idToken) => onAuthSuccess(idToken, product)}
          onAuthFailure={onAuthFailure}
          prefilledEmail={prefilledEmail}
          onVisibilityChange={(visible) => {
            setIsVisible(visible)
          }}
          setAuthError={setError}
        />
      )}
    </StyledFormWrapper>
  )
}

export const onAuthSuccess = async (idToken: string, product: Product) => {
  try {
    let redirectUrl = product.getRedirectUrl()

    console.log(`authentication success: productUrl=${redirectUrl}`)
    if (
      !isLocalHost(redirectUrl) &&
      !isLocalHost(window.location.href) &&
      isURLOnSamePaperCutDomainAsWindow(redirectUrl)
    ) {
      console.log(`creating IDToken cookie`)
      await createIDTokenCookie(idToken)
    } else {
      const redirectUrlWithIdToken = new URL(redirectUrl)
      redirectUrlWithIdToken.searchParams.set('idToken', idToken)
      redirectUrl = redirectUrlWithIdToken.toString()
    }
    window.location.href = redirectUrl
  } catch (e) {
    console.error(`failed handle auth success: ${e}`)
  }
}

// "https://au-staging.hive.papercut.software/someQueryParam=123" =? { tld: "software", sld: "papercut"}
const extractTopAndSecondDomains = (url: string): { tld: string; sld: string } => {
  try {
    const hostname = new URL(url).hostname
    const parts = hostname.split('.')
    const tld = parts[parts.length - 1] // top level domain, like "com" or "software"
    const sld = parts[parts.length - 2] // second level domain, like "papercut"

    return { tld, sld }
  } catch (error) {
    console.error(`Invalid URL: ${url}`)

    return { tld: '', sld: '' }
  }
}

export const isURLOnSamePaperCutDomainAsWindow = (url: string): boolean => {
  const windowURL = window.location.href

  const windowURLDomains = extractTopAndSecondDomains(windowURL)
  const urlDomains = extractTopAndSecondDomains(url)

  return windowURLDomains.tld === urlDomains.tld && windowURLDomains.sld === urlDomains.sld
}

const onAuthFailure = (err: unknown) => {
  console.error(`onAuthFailure: ${err}`)
}

export default TenantLogin
